.sidenav-1 {
  z-index: 1;
  background-color: #0E4C90;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.sidenav-1 a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  margin-bottom: 20px;
}

.sidenav-1 a:hover {
  color: #f1f1f1;
}

.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenav-1 {
    padding-top: 15px;
  }
  .sidenav-1 a {
    font-size: 18px;
  }
}
