body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.landing-copy{
  padding: 5em;
}

.mar-b-16{
  margin-bottom: 16px;
}

.no-l-padding{
  padding-left: 0px;
}

.no-padding{
  padding: 0px;
}

.categories-container {
  display: block;
  text-align: center;
  padding: 2em 0.5em;
}

.add-item-btn{
  margin: 12px auto;
}
.title-container{
  display: block;
  width: 100%;
  text-align: left;
}
.title-left-container{
  width: 80%;
  text-align: left;
  padding: 12px 8px;
  display: inline-block;
}
.title-right-container{
  width: 19%;
  text-align: right;
  padding: 12px 8px 16px 8px;
  display: inline-block;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  padding: 10px 6px !important;
  color: #0E4C90;
  font-weight: bold;
}
.ReactTable .rt-tbody .rt-td{
  border-color: #0E4C90;
  padding: 10px 6px;
}
.action-icon{
  font-size: 18px;
  padding: 8px 10px;
  cursor: pointer;
  margin-top: 12px;
  border-radius: 4px;
  background-color: #fff;
  color: #0E4C90;
}
.action-icon:hover{
  background-color: #0E4C90;
  color: #fff;
}
.action-icon.delete{
  color: #be0027;
  background-color: #fff;
}
.action-icon.delete:hover{
  background-color: #be0027;
  color: #fff;
}
.action-icon.add{
  color: #fff;
  background-color: #0E4C90;
  position: fixed;
  bottom:10px;
  right:10px;
  width: 200px;
}
.action-icon.add p{
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 0px;
}
.action-icon.add:hover{
  color: #0E4C90;
  background-color: #fff;
}
.custom-r-table-r{
  width: 63%;
  margin: 0 auto;
}
.story-action{
  display: block;
  text-align: left;
}
.story-action .action-icon{
  display: inline-block;
}
.ReactTable .-pagination .-btn{
  background-color: #0E4C90 !important;
  color: #fff !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0e4c90;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}