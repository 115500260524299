.clippath-style {
    clip-path: circle(50%);
}

.clippath-style.yes{
    clip-path: circle(30%);
}

.clippath-style.no{
    clip-path: circle(80%);
}
.text-align-r{
    text-align: right;
}
.story-data{
    table-layout: fixed;
}
.story-data tr th:nth-child(1),.story-data tr td:nth-child(1){
    width: 80px;
    max-width: 80px;
}
.story-data tr th:nth-child(2),.story-data tr td:nth-child(2){
    width: 100px;
    max-width: 100px;
}
.story-data tr th:nth-child(3),.story-data tr td:nth-child(3){
    width: 150px;
    max-width: 150px;
}
.story-data tr th:nth-child(4),.story-data tr td:nth-child(4){
    width: 400px;
    min-width: 400px;
    padding: 0.15rem 0.75rem;
}
.story-data tr th:nth-child(4) img,.story-data tr td:nth-child(4) img{
    max-width: 320px;
}
.custom-tags, .fEDVdc{
    position: relative;
    background-color: #fff !important;
    display: block !important;
    padding: 8px 12px;
    margin: 1rem 0 !important;
    text-align: left !important;
    width: 100% !important;
    border: 1px solid #717171 !important;

}
.cNrjqJ{
    position: relative !important;
    display: block !important;
    box-shadow: none !important;
    background: #fff !important;
    width: 700px !important;
    top: 190px !important;
    left: 300px !important;
    height: 2s00px;
}
.tags-row{
    padding: 0px 15px;
    min-height: 200px;
}
.padding-l-15{
    padding-left: 15px;
}